import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { colors, GreenLink } from "../style/theme";
import styled from "@emotion/styled";
import FeedCard from "../components/feed-card";

const InfoBox = styled.div({
  minWidth: "200px",
  display: "flex",
  marginLeft: "0.5rem",
  "& p": {
    ":nth-of-type(1)": {
      fontWeight: "bold",
      marginLeft: "0.2rem",
    },
    ":nth-of-type(2)": {},
  },
});

const A = styled(GreenLink)({
  color: colors.dark,
  ":hover, :active": { color: colors.green },
});

function BlogPage({ data }) {
  const blog = data.postgres.blog;
  const feeds = data.postgres.feeds;
  const { title, url, category, country, creator, feedUrl, tags } = blog;
  return (
    <Layout>
      <div>
        <div
          css={{
            margin: "1.5rem auto",
            boxShadow: "1px 2px 15px #e5e5e5",
            backgroundColor: colors.light,
            padding: "1rem",
          }}
        >
          <h4 css={{ color: colors.red }}>
            بطاقة المدوّنة
            <span>
              <A
                href="mailto:admin@elfehrest.com"
                css={{
                  fontWeight: "normal",
                  fontSize: "55%",
                  marginTop: "1rem",
                  opacity: 0.7,
                  marginRight: "0.4rem",
                }}
              >
                (اقترح تعديلًا)
              </A>
            </span>
          </h4>
          <div css={{ display: "flex", flexWrap: "wrap" }}>
            <InfoBox>
              <p>عنوان المدّونة:</p>
              <p>{title}</p>
            </InfoBox>
            <InfoBox>
              <p>المدوّن/ة:</p>
              <p>{creator}</p>
            </InfoBox>
            <InfoBox>
              <p>التصنيف:</p>
              <p>{category}</p>
            </InfoBox>
            <InfoBox>
              <p>البلد الأم للمدوّن/ة:</p>
              <p>{country}</p>
            </InfoBox>
            <InfoBox>
              <p>الأوسمة:</p>
              <p> {tags}</p>
            </InfoBox>
          </div>

          <A href={url} rel="noopener noreferrer" target="_blank">
            رابط المدونّة
          </A>
          <span> ، </span>
          <A href={feedUrl} rel="noopener noreferrer" target="_blank">
            رابط الخلاصات
          </A>
        </div>

        <div
          css={{
            boxShadow: "1px 2px 15px #e5e5e5",
            backgroundColor: colors.light,
            padding: "1rem",
          }}
        >
          <h4 css={{ margin: 0, color: colors.red }}>
            أحدث التدوينات المنشورة
          </h4>
        </div>
        <div css={{}}>
          {feeds.map((feed) => (
            <FeedCard
              node={{ ...feed, blogTitle: blog.title }}
              key={feed.link}
              light={true}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
}

BlogPage.propTypes = {
  data: PropTypes.object,
};

export default BlogPage;

export const pageQuery = graphql`
  query ($blogId: Int!) {
    postgres {
      blog: blogById(id: $blogId) {
        title
        url
        category
        country
        creator
        feedUrl
        id
        tags
      }
      feeds: allFeedsList(
        condition: { blogId: $blogId }
        orderBy: ISO_DATE_DESC
        first: 3
      ) {
        blogId
        title
        publishedAt
        url
        isoDate
        offlineImage
        contentSnippet
        categories
      }
    }
  }
`;
